:root {
	--rv-toast-position-top-distance: 45% !important;
}

.app {
	width: 100vw;
}
@font-face {
	font-family: "w9";
	src: url("./assets/fonts/w9.woff");
}

@font-face {
	font-family: "w12";
	src: url("./assets/fonts/w12.woff");
}

/* *针对一些场景， 设置toast 的位置偏上一点。 */
.upward-toast {
	margin-top: -90px;
}

body {
	background-color: #fb4137;
}

optgroup {
	font-size: 24px;
}

/* 定义一个名为zoomInOut的动画，实现元素从100%尺寸到120%再回到100%的过程 */
@keyframes zoomInOut {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.95);
	} /* 在动画中间阶段放大至120% */
	100% {
		transform: scale(1);
	} /* 动画结束时恢复至原始尺寸 */
}

/* 将这个动画应用到具体的元素上，并设置动画持续时间和循环次数 */
.zoom-animated {
	animation-name: zoomInOut;
	animation-duration: 1s; /* 动画总时长为2秒 */
	animation-iteration-count: infinite; /* 无限次循环播放动画 */
}
