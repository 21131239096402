.overlayContianer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 190px;
}

.button {
  z-index: 2;
  border: 0;
  top: 90px;
  right: 0;
  position: fixed;
  width: 62px;
  height: 162px;
  background: url("../../assets/flag.png") no-repeat center/100% 100%;
}
.container {
  color: white;
  width: 665px;
  height: 985px;
  margin-left: -38px;
  position: relative;
  padding: 130px 40px 230px 100px;
  box-sizing: border-box;
  background: url("../../assets/activity_rules.png") no-repeat center/100% 100%;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  width: 63px;
  height: 63px;
  background: url("../../assets/close2.png") no-repeat center/100% 100%;
  &:active {
    transform: scale(0.95);
  }
}

.content {
  width: 100%;
  height: 100%;
  line-height: 1.8;
  font-size: 20px;
  overflow: auto;
  font-family: "w9";
}
